.inclusions-container {
  display: flex;
  flex-direction: column;
  padding-top: 20px !important;
  padding-right: 29px;
  height: 100%;
  align-items: center;
}
.inclusions-form-container {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  justify-content: flex-start;
  gap: 0.7rem;
  align-items: center;
  width: 913.59px;
}
.inclusions-first-row {
  display: flex;
  width: 70%;
  justify-content: space-around;
}
.inclusions-first-row-prive{
  display: flex;
  width: 70%;
  justify-content: space-between;
}
.add-inclusion > button {
  padding: 0;
}
.inclusion-details-container {
  width: 70%;
  padding: 6px;
}
.inclusion-details-input {
  height: 100px;
}
.inclusion-input {
  width: 360px;
}
.inclusions-frequency-select {
  width: 250px;
}
.inclusion-main-select-frequency {
  width: 250px;
}
.inclusions-form-main-container > button {
  padding: 0;

  margin-left: -6px;
}

.inclusion-main-select {
  width: 360px;
}
.inclusions-map {
  margin-top: 0.9rem;
}
.inclusions-container label {
  margin-bottom: 0;
}
.inclusions-map button {
  border: none;
  background: white;
}
.inclusion-add-btn span:nth-child(2) {
  font-family: 'Noto Sans Display';
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 22px;
  font-weight: 400;
}
.inclusion-add-btn {
  width: 77%;
  margin-top: -25px;
}
.inclusions-map button {
  padding: 0;
}
.magin-top-10 {
  margin-top: 2rem;
}
.list-inclusion-details-container {
  padding: 2px;
  width: 67.5%;
  margin-right: 22px;
}
