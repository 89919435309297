.advanced-booking-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3em;
  margin-bottom: 5rem;
}
.booking-radio {
  display: flex;
  gap: 0.9em;
  padding: 0.5rem;
}
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.booking-info-container {
  margin-bottom: 1em;
}
.radio-extra-class {
  margin-top: -3px;
}

.modal-paragraph{
  font-size: 23px;
  font-weight: 100;
  margin-top: 15px;
  font-family: 'Noto Sans Display';
  line-height: 1.5;
}

.root-modal{
  display: flex;
  flex-direction: column;
}

.modal-checkbox {
    font-family: 'Noto Sans Display';
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
}

.modal-ok-button {
  display: flex;
  justify-content: center;
}