.banner-root {
  display: flex;
  align-items: center;
  border: 1px solid #b70404;
  border-left-width: 5px; 
  background-color: #ffeaed;
  padding: 20px;
  margin-top: 10px;
}

.banner-root > * {
  margin-left: 10px;
}

.banner-root h2 {
  font-family: 'Noto Sans Display', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

.banner-root p {
  font-family: 'Noto Sans Display', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
}
