.days {
  display: grid;
  grid-template-columns: 7rem 7rem 7rem 7rem;
  justify-content: center;
}
.valid-days-hr-line {
  width: 889px;
  height: 1px;
  background-color: #0404042b;
  margin: 0 0 18px;
}
.valid-days {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.valid-days-main {
  display: flex;
  justify-content: center;
}

.max-min-input {
  display: flex;
  justify-content: center;
  gap: 5rem;
  margin-bottom: 75px;
}
.max-min-hr {
  margin: 24px 121px 40px;
  background-color: #a9a4a4;
}
.valid-days-para {
  text-align: center;
  margin-bottom: 1.5rem;
}
.valid-days > p {
  margin-bottom: 1.5rem;
  margin-top: 2rem;
}
.extra-space {
  margin-bottom: 7px;
}
.validays-input {
  height: 44px;
  width: 73px;
}
.min-container {
  display: flex;
  align-items: center;
}
.min-container > span {
  margin-left: -6rem;
}
.min-max-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.valid-days-container {
  align-items: center;
}
.offer-code-hr {
  border: 0;
  height: 1px;
  background-color: #979797;
  margin: 0 164px 18px;
  width: 889px;
}
