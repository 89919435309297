.offer-information-data {
  height: 91px;
  width: 546px;
  border: 1px solid black;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  padding: 1.25rem;
  align-items: center;
  border-style: dashed;
}

.infoText {
  text-transform: capitalize;
}

.information {
  width: 100%;
  overflow-wrap: break-word;
}

.b-form-textarea__label {
  display: flex;
  justify-content: space-between;
}

.review-and-submit-see-more {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.review-and-submit-see-more svg {
  margin-right: 2px;
}
