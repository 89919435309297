.offer-code-info2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.offer-code-hr {
  border: 0;
  height: 1px;
  background-color: #979797;
  margin: 0 164px 18px;
  width: 889px;
}
.offer-code-info {
  margin-bottom: 140px;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

.code1-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.code1-info {
  display: flex;
  flex-direction: column;
  width: 28%;
  margin: 0 auto;
}
.code1-info > button {
  padding: 0;
}

.code1-link input {
  width: 157px;
}
.offer-code-use {
  width: 285px;
}
.offer-code-use-disabled {
  width: 394px;
  opacity: 0.6;
  color: #b6b6b6;
}
.code1-link > label {
  margin-bottom: 0;
}
.line-1 {
  font-family: 'Noto Sans Display';
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  gap: 7px;
}
.code1-link > button {
  margin-left: -22px;
}
.additional-offer-code-added {
  margin-bottom: 10px;
}
.addtional-offer-delete-button {
  border: none;
  background-color: #fff;
}

.error-componenet {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.error-message {
  font-size: 12px;
  color: red;
  width: max-content;
}
.offer-code-description {
  white-space: pre-line;
}
.offer-code-separator{
  height: 20px;
}
.website-offer-hbm-subtext{
  margin-bottom: 10px;
}
