.container {
  display: flex;
  flex-direction: column;
  padding-top: 20px !important;
  padding-right: 29px;
  height: 100%;
  h3 {
    font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #282828;
    font-weight: 900;
    font-size: 1rem;
  }
  .dash-heading {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
}

.date-chooser-panel {
  display: flex;
  background-color: #eeeeee;
  border: 1px solid #dcdcdc;
  width: fit-content;
  padding: 5px;
}

.label {
  margin: 0 15px 0 0;
  cursor: pointer;
  padding: 10px;
  font-family: 'Noto Sans Display';
  &.active {
    background-color: #fbfbfb;
    border: none;
  }
}

.search-filters {
  display: flex;
  margin-top: 10px;
  & > *:not(:first-child, :last-child) {
    margin-left: 10px;
  }
  & > *:first-child {
    flex: 0 0 20%;
    margin-left: 0;
  }
}

.search-input {
  padding-left: 40px;
  background-image: url('../../components/Icons/searchIcon.svg');
  background-repeat: no-repeat;
  background-position: 10px center;
}

.ag-theme-alpine {
  .ag-header {
    background-color: #dedede;
  }
  .v-grid-cell {
    border: none !important;
    z-index: 2;
  }
  .ag-row {
    --ag-range-selection-border-style: none;
  }
}

.ag-header-cell-label {
  justify-content: space-between;
}

.ag-row-focus {
  z-index: 9999;
}

.bottom-componenet {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-family: 'Yeseva One, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif';
}

.offerId {
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    padding-right: 10px;
  }
  svg {
    cursor: pointer;
  }
}

.empty-result-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 65px);
  border: 1px solid #eaeaea;
  background-color: #ffffff;
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  h1 {
    font-size: 22px;
  }
}

.date-chooser {
  width: 200px;
}

.copiedRow {
  background-color: #ecf6ed !important;
}

.ag-paging-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding-top: 18px;
  .ag-paging-row-summary-panel {
    display: none;
  }

  .ag-paging-button {
    padding: 6px 8px;
    border: 1px solid #dcdcdc;
    &:hover {
      padding: 6px 8px;
      border: 1px solid #bbb;
      background-color: #dcdcdc;
    }
  }

  .ag-disabled,
  .ag-disabled:hover {
    background-color: #eee;
    border: 1px solid #dcdcdc;
  }
}
