.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fefefea6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: relative;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  max-height: 75%;
  padding: 50px;
  border: 1px solid #0000006e;
  overflow: auto;
}
.modal-small{
  width: 20%;
}
.modal-medium{
  width: 30%;
}
.modal-large{
  width: 500px;
}

.modal-header {
  display: flex;
}

.modal-header h2 {
  font-family: 'Yeseva One';
  font-size: 25px;
  line-height: 1.273;
  font-weight: 400;
}
.icon-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: -20;
}

.modal-header button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.5rem;
}
.modal-close {
  position: absolute;
  top: 22px;
  right: 30px;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.modal-close-icon {
  display: flex;
  justify-content: flex-end;
  width: 100px;
  height: 100px;
}
.modal-header span {
  width: 30px;
  cursor: pointer;
}
