.blackout-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blackout-input-select {
  display: flex;
  gap: 1rem;
  width: 774px;
}
.blackout-data {
  margin-bottom: 240px;
  margin-top: 70px;
}
.blackout-date-btn > button {
  padding: 0;
}
.blackout-date-btn .b-form-select {
  margin-bottom: 0;
}
.blackout-dates-select {
  width: 360px;
}
.blackout-add-button {
  margin-left: -5px;
}
.blackout-delete-button {
  border: none;
  background-color: #fff;
}
.blackout-items {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 15px;
}
.blackout-data-main {
  display: flex;
  align-items: center;
  color: #282828;
  font-size: 87.5%;
  background-color: #fff;
  border-radius: 0;
  border: 1px solid #ccc;
  box-shadow: inset 0 0 5px 0 #ddd;
  width: 163px;
}
.blackout-data-input {
  border: none;
  height: 44px;
  width: auto;
  line-height: 1.3;
  font-size: 16px;
  padding: 12px;
  width: 100%;
  font-family: 'Noto Sans Display';
}

.blackout-items > label {
  margin-bottom: 0;
}

.blackout-add-button span:nth-child(2) {
  font-family: 'Noto Sans Display';
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 22px;
  font-weight: 400;
}

.blackout-error-message {
  margin-top: 7px;
  width: 75%;
}

.blackout-error-message .error-container{
  display: flex;
}
.booking-dates-section{
  background-color: lightgray;
  padding: 20px;
  display: flex;
  margin: 5px 0px 25px 0px;
}
.booking-dates-column{
  display: inline;
  width: 30%;
}
.booking-dates-block{
  display: block;
}