.start-end {
  display: flex;
  gap: 6rem;
  margin-top: 0.4rem;
  margin-bottom: 20px;
  .p-base-small{
    font-weight: bold;
  }
}

.start-end-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.start-end-dates {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2rem;
}
.booking-date-picker {
  width: 254px !important;
}

.enter-date-info {
  padding: 0;
}

.error-component {
  display: flex;
}

.error-label {
  font-size: 12px;
  color: red;
}

.date-section {
  margin-bottom: 25px;
}

.mb-0 {
  margin-bottom: 0;
}
