.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  font-family: 'Yeseva One';
  font-weight: 400;
  background-color: #f8f8f8;
  margin-top: -100px;
}

.not-found-heading {
  font-size: 6rem;
  color: #0072ce;
}

.not-found-text {
  font-size: 2rem;
}

.not-found-description {
  font-size: 1.2rem;
  max-width: 400px;
  margin-top: 20px;
  font-family: 'Noto Sans Display';
}

.not-found-clickHere {
  margin-top: 20px;
}
