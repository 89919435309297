.timeline {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.timeline-event {
  margin: 2px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.timeline-date {
  font-weight: bold;
}

.timeline-header {
  display: flex;
  justify-content: space-between;
}

.timeline-footer {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}

.commentsEditor {
  width: 100%;
  height: 140px;
  border: 1px solid #949494;
  border-radius: 1px;
}

.timeline-add-comment-button{
    display: flex;
    float: right;
}

.timeline-button-cancel{
    margin-right: 10px;
}