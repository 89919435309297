.b-button {
  margin: 0;
  font-weight: bold;
  display: flex;
  font-size: 14px;
  align-items: center;
  gap: 3px;
}

.b-button-default {
  padding: 10px 10px;
  height: fit-content;
  color: #0072ce;
  background-color: #ffffff;
  text-transform: initial;
}

.b-button-default:hover {
  text-decoration: underline;
}

.b-button:disabled {
  padding: 10px 30px;
  margin-left: -6px;
  margin: 0;
  font-weight: bold;
  display: flex;
  font-size: 14px;
  align-items: center;
  gap: 3px;
  justify-content: center;
}

.b-button-blue {
  padding: 10px 30px;
  height: fit-content;
  color: #0072ce;
  background-color: #e8f0fe;
  border: 1px solid #0072ce;
}

.b-button-green {
  padding: 10px 30px;
  height: fit-content;
  color: #14711b;
  background-color: #ebf6ed;
  border: 1px solid #14711b;
}

.b-button-red {
  padding: 10px 30px;
  height: fit-content;
  color: #b70404;
  background-color: #ffeaed;
  border: 1px solid #b70404;
}
