.container {
  display: flex;
  flex-direction: column;
  padding-top: 20px !important;
  padding-right: 29px;
  height: 100%;
  margin-bottom: 50px;
}

.container .dash-heading {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.container h3 {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #282828;
  font-weight: 900;
  font-size: 1rem;
}

.date-chooser-panel {
  display: flex;
  background-color: #eeeeee;
  border: 1px solid #dcdcdc;
  width: fit-content;
  padding: 5px;
}

.label {
  margin: 0 15px 0 0;
  cursor: pointer;
  padding: 10px;
  font-family: 'Noto Sans Display';
}

.label.active {
  background-color: white;
  border: none;
}

.search-filters {
  display: flex;
  margin-top: 10px;
}

.search-filters > *:not(:first-child, :last-child) {
  margin-left: 10px;
}

.search-filters > *:first-child {
  flex: 0 0 20%;
}

.search-input {
  padding-left: 40px;
  background-image: url('../../components/Icons/searchIcon.svg');
  background-repeat: no-repeat;
  background-position: 10px center;
}

.ag-theme-alpine .ag-header {
  background-color: #dedede;
}

.ag-theme-alpine .v-grid-cell {
  border: none !important;
  z-index: 2;
}

.ag-theme-alpine .ag-row {
  --ag-range-selection-border-style: none;
}

.ag-header-cell-label {
  justify-content: space-between;
}

.ag-row-focus {
  z-index: 9999;
}

.bottom-componenet {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-family: 'Yeseva One, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif';
}

.pagination {
  display: flex;
}

.pagination li {
  margin-left: 5px;
  list-style-type: none;
  font-weight: 900;
  border: 1px solid #dcdcdc;
  padding: 5px 10px 5px 10px;
}

.pagination li a {
  color: #181d1f;
  cursor: pointer;
  font-family: 'Open Sans';
}

.active {
  background-color: #babfc7;
}

.active-pagination {
  background-color: #0172ce;
}

.active-pagination a {
  color: #ffffff !important;
}

.offerId {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.offerId div {
  padding-right: 10px;
}

.offerId svg {
  cursor: pointer;
}

.empty-result-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 65px);
  border: 1px solid #eaeaea;
  background-color: #ffffff;
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.empty-result-root h1 {
  font-size: 22px;
}

.date-chooser {
  width: 200px;
}

.copiedRow {
  background-color: #ecf6ed !important;
}
.Modal_custom_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.Modal_comments_content {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  text-align: left;
  margin-top: 20px;
}
.comments_status_date {
  font-weight: 900;
}
.comments-line-content {
  margin-bottom: 5px;
}
.button-container {
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.bottom-componenet {
  margin-bottom: 75px;
}

.action-btn {
  height: 38px;
  margin-top: 4px;
  border: transparent 1px solid;
  background-color: transparent;
}

.action-btn:hover > svg {
  opacity: 1;
}

.action-btn > svg {
  padding: 10px;
  cursor: pointer;
  box-sizing: content-box;
  opacity: 0.7;
}

.ag-cell {
  line-height: 48px !important;
}

.ag-cell > div {
  height: 100%;
}

.ag-cell-focus,
.ag-cell {
  border: none !important;
}

.spinning-state {
  position: absolute;
  top: 60%;
  left: 56%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.Modal_custom_content_extend_offer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h2 {
  margin: 0;
}

.extend-offer-option {
  display: flex;
  gap: 1rem;
  margin-top: 10px;
}

.extendHeading {
  font-size: 16px;
  padding: 15px 0;
}

.extend-inclution-add-btn {
  padding: 0;
  align-items: start;
}

.extendedOfferCode {
  align-items: flex-start;
}

.extendedOfferCode > button {
  margin-left: -35px;
}

.extendComments {
  width: 360px;
  height: 140px;
}

.end-offer-component {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.end-offer-component p {
  margin: 10px 0;
}

.popupModal {
  top: 0%;
  left: 20%;
  transform: translate(-50%, -50%);
}

.clear-button {
  background-color: #fbfbfb;
}

.view-more-content {
  margin-top: 15px;
  font-size: medium;
  font-weight: 500;
  max-height: 400px;
  overflow: auto;
}

.copy-request-error {
  color: rgb(177, 44, 44);
}
