.b-form-input__control:read-only {
  border: 1px solid #ccc;
  box-shadow: none;
  padding-left: 12px;
}
.noto-700 {
  font-family: 'Noto Sans Display';
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
}
