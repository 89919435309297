.specific-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.specific-text {
  font-family: 'Noto Sans Display';
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}
.specific-radio {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
}

.specific-radio-main-container {
  height: 61px;
  width: 526px;
}
.specific-select {
  width: 354px;
  height: 44px;
}
.specific-select-label {
  font-size: 12px;
  font-family: 'Noto Sans Display';
  font-weight: 400;
  line-height: 16px;
}

.specific-select-container{
  margin-top: 25px;
}

.specific-options {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  min-width: 600px;
}

.min-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 600px;;
}

.htb-hr{
  min-width: 780px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.leadtime-text-1 {
  font-size: 16px;
  font-family: 'Noto Sans Display';
  line-height: 24px;
}

.leadtime-input {
  height: 44px;
  width: 57px;
  font-size: 16px;
  padding: 5px;
}

.leadtime-input-label {
  padding: 5px;
  font-family: 'Noto Sans Display';
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.leadtime-input-main {
  margin-top: 7px;
}

.leadtime-container{
  min-width: 600px;
}

.max-min-input-container {
  display: flex;
  justify-content: center;
  gap: 5rem;
}
.offer-update-label{
  margin-bottom: -20px;
}