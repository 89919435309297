.dropdown {
  position: relative;
  display: inline-block;
  width: 250px;
}

.dropdown-button {
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  cursor: pointer;
  text-align: left;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-button:focus {
  outline: none;
}

.dropdown-icon {
  transition: transform 0.2s;
}

.dropdown-icon.open {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px;
  box-sizing: border-box;
  max-height: 350px;
  overflow: auto;
  hr {
    margin: 0;
    margin-top: 10px;
  }
  .dropdown-footer {
    cursor: pointer;
    color: #0072ce;
  }
}

.dropdown-menu div {
  display: flex;
  justify-content: space-between;
  color: #0072ce;
  margin: 5px 0;
}

.dropdown-menu input {
  margin-right: 10px;
}
