p.body1 {
  font-family: 'Noto Sans Display';
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  color: #282828;

  line-height: 1.5;
}

p.body2 {
  font-size: 1rem;
}

p.body3{
  font-weight: bolder;
  font-family: 'NotoSansDisplay-Regular_Light', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif;
  font-size: 20px;
  text-align: center;
  color: #282828;
  /* width: 984px; */
  line-height: 1.5;
}

p{
  padding: 0;
}