.tile-container {
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2px;
  width: 85%;
  margin: 6% auto;
}
.promotions-card {
  font-family: 'Yeseva One', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif;
  height: 157px;
  width: 259.16px;
  background-color: white;
  border: 1px solid #e1e1e1;
  border-width: 1px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 27%);
}
.promotions-card:hover,
.promotions-btn-active {
  border: 1px solid #0072ce;
  border-width: 2px;
}
.tile-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  margin-top: 50px;
}
.promotions-card-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 1rem;
  justify-content: start;
  margin: 4rem auto;
}
.promotions-card-container > button + button {
  margin-left: 0;
}
.promotion-info {
  font-family: 'Noto Sans Display';
  font-size: 16px;
  font-weight: 400;
}
.promotion-heading {
  font-family: 'Yeseva One';
  font-size: 22px;
  font-weight: 400;
  line-height: 1.273;
}
