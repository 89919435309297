.select-info {
  display: flex;
}
.select-info-span {
  padding-right: 0.5rem;
}
.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('../../Icons/chevron-down.svg');
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 30px;
  padding-right: 90px;
}

.dropDownReporting {
  background-color: #ededed;
  color: #717171;
}

.select-disabled{
  width: 100%;
}
