.file-input-container {
  display: flex;
  flex-direction: column;
}

.file-label {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.file-name {
  height: 44px;
  width: 360px;
  border: 1px solid #949494;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 12px;
}

.file-description {
  height: 49px;
  width: 359px;
}

.file-button {
  height: 44px;
  width: 175px;
  border: none;
  color: white;
  font-family: 'Noto Sans Display';
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 22px;
  background-color: #0072ce;
  border-radius: 35px;
}

.uploadedfile-container {
  margin-top: -20px;
}

.uploadedfile-clear-button {
  display: inline-block;
  margin-left: -20px;
}