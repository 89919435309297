.success-submit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success-submit-button {
  font-family: 'Noto Sans Display';
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 700;
  line-height: 22px;
  margin-top: 22px;
}
