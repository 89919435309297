@import "~quill/dist/quill.snow.css";

.container {
  padding-top: 1.5rem;
  padding-left: 29px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.main {
  margin-top: 64px;
  height: calc(100% - 65px);
}

.p-base-normal {
  font-family: 'Noto Sans Display';
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
.p-base-medium {
  font-family: 'Noto Sans Display';
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.p-base-small {
  font-family: 'Noto Sans Display';
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.p-base-width-small input {
  width: 148px;
}
.p-base-plus {
  label,
  fieldset {
    margin-bottom: 0;
  }
  .plus-button {
    margin-bottom: 1rem;
    padding: 0;
  }
}
.b-form-select__control {
  font-family: 'Noto Sans Display';
}
.warning-icon {
  display: flex;
  align-items: center;
  margin: -10px 0 10px;
}

.date-picker .p-base-small {
  margin-bottom: 8px;
  display: inline-block;
}
.radio-button-align {
  margin-top: -2.8px;
}

.checkbox-align-extra {
  margin-top: -3px;
}

.ag-root-wrapper.ag-layout-normal {
  min-height: 60vh;
}

.inline-error {
  display: flex;
  align-items: center;
  margin-top: -10px;
  margin-bottom: 10px;
}

