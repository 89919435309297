.actions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.actions > * {
  margin: 5px;
}

.footer-nav {
  box-shadow: 0px 0 16px -6px #282828;
  padding: 11px;
  position: fixed;
  height: 64px;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 5px 0;
}

.save-btn{
  width: fit-content;
  padding: 5px;
  margin-left: 25px;
}
