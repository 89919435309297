.prive-container {
  .priveBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    .firstSection {
      .luxury-consortia-options-checkbox-container {
        display: flex;
        flex-wrap: wrap;
        width: 460px;
        margin-top: 1rem;
        .luxury-consortia-options-checkbox {
          flex: 0 0 50%;
        }
      }
    }
    .sectiontwo {
      width: 460px;
    }
    .sectionthree {
      width: 460px;
      .max-min-input {
        display: flex;
        justify-content: flex-start;
        gap: 5rem;
      }
    }
    .title {
      font-family: 'Noto Sans Display';
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      text-align: left;
    }
    CheckBox {
      opacity: 1;
      color: rgba(40, 40, 40, 1);
      font-family: 'NotoSansDisplay-Regular';
      font-size: 14px;
      font-weight: 400;
      font-style: Regular;
      letter-spacing: 0px;
      text-align: left;
      line-height: 22px;
    }
  }
  .prive-error-banner {
    display: flex;
    justify-content: center;
    .prive-banner{
      display: flex;
      align-items: center;
      border: 1px solid #b70404;
      border-left-width: 5px; 
      background-color: #ffeaed;
      padding: 20px;
      margin-top: 10px;
      width: 75%;
    }
  }
  .prive-error-message {
    display: flex;
    align-items: center;
    margin-top: -10px;
    margin-bottom: 10px;
  }
  .prive-participate{
    display: flex;
    width: 200px;
  }
  .radio-item{
    flex: 1;
  }
  .checkbox-label{
    margin-bottom: 20px;
  }
  .valid-days-para{
    margin-bottom: 0;
    text-align: left;
  }
  .valid-days-sub-para{
    margin-bottom: 1rem;
    text-align: left;
  }
}
