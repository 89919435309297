.inclusions-htb {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inclusions-type-label {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  font-family: 'Noto Sans Display';
}

.tools-inclusions-type-label {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  font-family: 'Noto Sans Display';
  margin-top: -20px;
}
.promotion-type {
  width: 455px;
  height: 44px;
}
.parking-type {
  width: 253px;
  height: 44px;
}
.inclusions-main > label {
  margin-bottom: 10px;
  margin-top: 2rem;
}
.error-banner{
  width: 75%;
}
.inclusions-main{
  width: 300px;
}
.inclusions-type-sub{
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  font-family: 'Noto Sans Display';
  width: 400px;
}
.breakfast-type-label{
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  font-family: 'Noto Sans Display';
  margin-top: -10px;
}
.credit-error{
  display: flex;
}