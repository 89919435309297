.basics-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .basics-form {
    margin-top: 2rem;
  }
}
.basics-info-elements {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0 1rem;
  padding: 0;
  border: none;
}
legend {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.inline-form {
  flex-direction: row;
  label {
    margin-right: 1rem;
  }
}
.add-email {
  font-size: 1rem;
  font-weight: 500;
  color: #007bc8;
  border: none;
  background-color: #fbfbfb;
}
.add-email .b-button {
  padding: 0;
}
.add-record-type {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;
  color: #007bc8;
  border: none;
  background-color: #fbfbfb;
  margin-top: -15px;
}
.add-record-type .b-button {
  padding: 0;
}
.plus {
  border-radius: 50%;
  margin-right: 5px;
  text-align: center;
  color: #007bc8;
}
.basics {
  color: #007bc8;
  font-weight: 400;
  padding: 2rem;
}
.hotel-name {
  display: flex;
  gap: 1rem;
}
.disabled-hotel-name-input {
  width: 360px;
}
.basic-form,
.basic-form:disabled {
  width: 360px;
}
.basic-form {
  margin-bottom: 0;
}

.additionalEmail {
  display: flex;
  align-items: baseline;
}

.chain-hotel-count-text {
  font-family: 'Noto Sans Display';
  font-size: 14px;
  color: #282828;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.spiritCodeContainer {
  margin-bottom: 18px;
}

.hotelLabel {
  font-family: 'Noto Sans Display';
  font-size: 12px;
}
