.discount-value {
  display: flex;
  align-items: center;
  width: 101px;
}
.offer-type {
  display: flex;
  align-items: center;
  gap: 3.5rem;
  margin-top: 1rem;
}
.perpendicular-line {
  width: 50%;
  margin: auto;
  margin-top: 10%;
  margin-bottom: 0;
  transform: rotate(90deg);
  border-color: #1a85fd;
}
.pricing-details-option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 180px;
  margin-top: 60px;
}
.hr-line {
  border: 0.5px solid #979797;
  height: 0.5px;
  width: 889px;
}
.sub-option1 {
  padding: 1rem;
}
.sub-option2 {
  padding: 1rem;
  width: 461px;
}
#equal-1 {
  text-align: left;
  margin-bottom: 1.3rem;
}
#percent-offer-type {
  margin-left: 5em;
}
.discount-value > label {
  width: 101px;
}
.offer-type-has-icon .offer-type-input {
  padding-right: 30px;
  padding-left: 12px;
}
.offer-rack {
  width: 360px;
}
.pricing-details-main > label {
  font-family: 'Noto Sans Display';
  font-weight: 400;
}
.pricing-details-checkbox > label {
  margin-bottom: 0px;
}
.pricing-details-checkbox > h2 {
  margin-bottom: 2.7rem;
  margin-left: 27px;
  margin-top: 10px;
}
.pricing-details-checkbox .b-form-checkbox__control {
  margin-top: 3px;
}
.pricing-modal-body {
  margin-top: 10px;
}
