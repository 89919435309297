.title {
  font-family: 'Yeseva One';
  text-align: center;
  display: block;
  margin-bottom: 1rem;
  color: #282828;
  font-weight: 400;
}

h1.title {
  font-size: 48px;
  line-height: 1.208;
}

h2.title {
  font-size: 2em;
}
h3.title {
  font-size: 1.5em;
}
