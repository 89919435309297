.advanced-ext {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.advnc-ext {
  display: flex;
  gap: 1.5rem;
}
.advanced-ext-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 565px;
  margin: 1.5rem 0 0 7rem;
}
.bonous-points-offer {
  display: flex;
  flex-direction: column;
}
.offer-fiels {
  display: flex;
  gap: 1rem;
}
.ext-box-1 {
  margin-top: 0;
}
.advanced-ext-main p {
  margin-bottom: 1rem;
}
.advnc-ext-input {
  width: 73px;
}
.advn-ext-select {
  width: auto;
}
.advanced-ext span {
  margin-bottom: 5px;
}
.offer-fiels p {
  margin-bottom: 6px;
}
.ext-box-1 p {
  margin-bottom: 6px;
}
