.welcome .tile-container {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2px;
  width: 60%;
  margin: 6% auto;
}
.welcome-btns {
  display: flex;
  justify-content: center;
  margin-bottom: 6rem;
  margin-top: 3rem;
}
.welcome-buttons-container {
  background: white;
  width: 345px;
  height: 209px;
  background-color: white;
  border: 1px solid #e1e1e1;
  border-width: 1px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 27%);
}

.welcome-buttons-container:hover,
.welcome-btn-active {
  border: 1px solid #0072ce;
  border-width: 2px;
}
.welcome-title {
  font-family: 'Yeseva One';
  font-size: 22px;
  line-height: 1.273;
  font-weight: 400;
}

.welcome-para {
  font-size: 16px;
  font-family: 'Noto Sans Display';
  font-weight: 400;
}
button {
  cursor: pointer;
}

.center {
  align-items: center;
}
