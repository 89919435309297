.root-toggle {
  margin-bottom: 20px;
}

.main-toggle {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  padding: 10px 20px;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #dcdcdc;
  font-family: 'NotoSansDisplay-Regular_Light', -apple-system, BlinkMacSystemFont, 'Segoe UI';
}

.toggle-heading {
  display: flex;
}

.toggle-heading div {
  margin-right: 10px;
}

.toggle-edit-icon {
  color: #0072ce;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggle-edit-icon span {
  margin-left: 5px;
}

.collapse-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  background-color: #ffffff;
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
}

.right-component {
  display: flex;
  gap: 15px;
}
