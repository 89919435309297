.right-help-panel {
  position: fixed;
  right: 0;
  top: 65px;
  padding: 20px 50px 20px 20px;
  z-index: 1;
  height: calc(100vh - 65px);
  background: #fff;
  box-shadow: 0 2px 4px 0px #7e7e7e;
  width: 300px;
  overflow: auto;
}

.right-help-panel .content .title {
  font-family: 'Noto Sans Display';
  font-size: 20px;
  font-weight: bold;
}
.right-help-panel .content .cross-icon {
  position: absolute;
  right: -31px;
  cursor: pointer;
  top: 5px;
}
.right-help-panel .content svg {
  height: 20px;
}
.right-help-panel .content {
  position: relative;
}

.help-text-description {
  font-family: 'Noto Sans Display';
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px;
}

.help-text-heading {
  font-weight: 600;
  font-size: medium;
  margin: 30px 0px 5px 0px;
}
