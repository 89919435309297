.comments-main {
  margin-top: 50px;
  .comments-box {
    height: 323px;
    width: 550px;
    border: 1px solid #949494;
    border-radius: 1px;
  }
}

.chain-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom-container {
  display: flex;
  gap: 10px;
}

.editable-container {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  width: 550px;
  min-height: 323px;
  overflow: auto;
}

.editable-chip {
  padding: 5px 10px;
  margin: 5px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #ffffff;
}
.valid {
  background-color: #0072ce;
}
.invalid {
  background-color: red;
}

.remove-button {
  width: 20px;
  color: #ffffff;
}

textarea {
  border: none;
  resize: none;
  font-size: 16px;
  outline: none;
  height: 200px;
  width: 500px;
  color: black;
}

.chip-input {
  color: black;
  width: 50px;
  border: none;
}

.footer-chain {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.inline-error {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.error-componenet {
  font-size: 12px;
  color: red;
}
.error-heading {
  width: 75%;
}

.custom-tooltip {
  background-color: #ffffff;
}
.chain-alert {
  top: 130px;
}
.edit-tick {
  margin-left: 5px;
  margin-top: 2px;
}

.clear-button {
  display: flex;
  cursor: pointer;
}

.icon-close {
  display: flex;
  height: 18px;
}

.find-hotels-container {
  display: flex;
  flex-direction: column;
  width: 97%;
  border: 1px solid #ededed;
  padding: 20px 0 0 15px;
  box-shadow: 2px 3px 0px 0px #dddddd;
  margin-top: 10px;
  .h1 {
    font-family: 'Noto Sans Display';
    font-size: 20px;
    font-weight: bold;
  }
  .filter-components {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
  }
}
