.date-picker {
  position: relative;
}
.custom-date-picker {
  background-color: white;
  border: 1px solid #ccc;
  height: 44px;
  display: flex;
  box-shadow: inset 0 0 5px 0 #ddd;
  align-items: center;
  width: 200px;
}
.custom-date-picker > label {
  font-weight: Normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 1rem;
  font-size: 16px;
}
.calender-info {
  display: flex;
}
.calender-info-span {
  padding-right: 0.5rem;
}
