.main-header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  background: #fbfbfb;
}
.wrapper {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  border: 1px solid #ffff;
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 6px 7px -6px #28282885;
  position: relative;
  z-index: 1;
  width: 1, 280px;
  height: 64px;
}

svg {
  display: inline-block;
  vertical-align: top;
}

h1 {
  font-weight: 900;
  font-size: 20px;
  line-height: 1;
  margin: 6px 0 6px 10px;
  display: inline-block;
  vertical-align: center;
}

button + button {
  margin-left: 10px;
}

.welcome {
  color: #282828;
  font-size: 14px;
  margin-right: 10px;
}

.logo {
  display: flex;
  flex-basis: auto;
  align-items: center;
}

.logo-container {
  border-right: 1px solid #eee;
  padding-right: 16px;
}

.panel-container {
  position: relative;
}
.panel {
  position: absolute;
  top: 41px;
  border: 1px solid #eee;
  padding: 20px;
  right: -18px;
  width: 100%;
  display: none;
}

button.signin {
  display: flex;
  align-items: center;
  border: none;
  margin: 0;
  font-size: 14px;
  height: 44px;
  text-transform: none;
  text-decoration: none;
  background: none;
  color: #282828;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
button.signin span {
  margin: 0 5px;
}
.flex-display {
  display: flex;
  justify-content: center;
  align-items: center;
}
.global-header {
  font-family: 'Noto Sans Display', sans-serif;
  font-weight: 700;
  margin-left: 16px;
  font-size: 20px;
}
.help-container {
  display: flex;
  border: none;
  background-color: #fbfbfb;
}
.bell-container {
  display: flex;
  border: none;
  background-color: #fbfbfb;
}
.help-container:hover {
  cursor: pointer;
}
.help-text {
  font-family: 'Noto Sans Display';
  font-size: 14px;
  color: #0072ce;
  line-height: 22px;
  letter-spacing: 1px;
}
