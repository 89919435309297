.alert {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: 1rem;
  border-left: 3px solid #f26826;
  width: 300px;
  box-shadow: 2px 0px 5px 0px #28282885;
  position: fixed;
  right: 60px;
  z-index: 1;
}

.alert-body {
  margin-left: 15px;
  color: #282828;
}

.alert h3 {
  color: #282828;
  font-weight: bolder;
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
}

.alert-error {
  color: #721c24;
  background-color: #f8d7da;
}

.copy-link-button {
  margin: 0;
  padding: 0;
  border: none;
  color: #282828;
  text-transform: uppercase;
  text-decoration: underline;
}

.alert svg {
  height: 20px;
  cursor: pointer;
}
