.root {
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: white;
  box-shadow: 0px 0px 5px 0px #28282885;
  margin: -10px 0 0;
  padding: 0;
  list-style: none;
  li {
    display: flex;
    justify-content: flex-start;
    font-weight: 400;
    margin: 0;
    a {
      display: flex;
      align-items: center;
      width: 100%;
      line-height: normal;
      font-family: 'Open Sans', 'Arial', sans-serif;
      font-size: 11px;
      &:hover {
        text-decoration: none;
        background-color: #eaf4fe;
      }
      .link-icon {
        padding: 8px 6px 4px 12px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
      .link-label {
        padding: 6px 12px 6px 0;
        width: 100%;
        text-align: left;
      }
    }
  }
}
