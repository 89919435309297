.property-website-radio {
  display: flex;
  gap: 5.5rem;
}
.property-website-info {
  width: 420px;
  margin-top: 0.7rem;
}
.property-website {
  align-items: center;
  margin: 5rem;
}
.porperty-text {
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.special-offer-title-label {
  font-family: 'Noto Sans Display';
  font-size: 16px;
  font-weight: 400;
}
.special-offer-title {
  width: 332px;
  height: 44px;
}

.property-date {
  width: 254px;
}
.property-datepicker-main > p {
  margin-bottom: 10px;
}
.property-additional-data-main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}
.translation-language-list{
  margin: 5px 0px 0px 15px;
}
.translation-required{
  font-weight: bold;
}
.warning-width{
  min-width: 15px;
}
.warning-message{
  color: orange;
}
.warning-component{
  display: flex;
  align-items: center;
  margin-top: -10px;
  margin-bottom: 10px;
}
.img-hbm{
  visibility: hidden;
  height: 1px;
  width: 1px;
}