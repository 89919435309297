.review-details {
  white-space: pre;
  margin: 0.5rem 0 1.5rem;
}
.textarea-medium {
  min-height: 140px;
}
.tabs {
  display: flex;
  justify-content: center;
  margin: 1rem 0 2rem;
  .tab {
    justify-content: center;
    align-items: center;
    width: 188px;
    height: 40px;
    background: #f9f7f7;
    border: #979797 1px solid;
    margin: 0;
  }
  .tab.active {
    background: #338ed8;
    font-weight: 600;
    color: #fff;
    border: none;
  }
}
.tab-content {
  .content {
    display: none;
    background-color: #eaeaea;
    padding: 10px;
  }
  .content.active {
    display: block;
    background: transparent;
    border: none;
  }
}
.data-services {
  max-width: 886px;
  margin: 0 auto;
}
.confirm-offer {
  max-width: 624px;
  margin: 0 auto;
}
.input-small {
  display: flex;
  label {
    .basic-form {
      width: 238px;
    }
  }
}

.ds-offercodes {
  display: flex;
  align-items: center;
}

.basics-info-elements {
  margin: 0;
}

.error-banner {
  margin-bottom: 20px;
}

.error-message {
  font-size: 12px;
  color: red;
}
