.file-uploader-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  gap: 2rem;
}
.drag-and-drop {
  width: 600px;
  height: 114px;
  border-radius: 4px;
  border: 1px dashed #949494;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.drag-heading {
  font-family: 'Noto Sans Display';
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
