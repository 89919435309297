.badge {
  padding: 0.25em 0.5em;
  border-radius: 4px;
  font-weight: bold;
  margin: 2px;
}

.success {
  background-color: #28a745;
  color: #fff;
}

.danger {
  background-color: #dc3545;
  color: #fff;
}
