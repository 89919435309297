.review-details {
  margin: 0.5rem 0 1.5rem;
}

.status-button {
  margin: 0 auto 1rem;
  cursor: auto;
}

.reject-reason {
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 80%;
  margin-left: 10%;
  text-align: center;
  color: #b70404;
}

.container {
  .content {
    display: none;
    background-color: #eaeaea;
    padding: 10px;
  }
  .content.active {
    display: block;
    background: transparent;
    border: none;
  }
}
