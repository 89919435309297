.property-website-radio {
  display: flex;
  gap: 5.5rem;
}
.property-website-info {
  width: 500px;
}
.property-website {
  align-items: center;
  margin: 0;
}
.property-text {
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.no-container {
  margin-top: 1rem;
}
.offer-title-text,
.change-offer-title-container {
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.offer-title {
  width: 285px;
}
.offer-code-change {
  width: 290px;
}

.property-website-info-prive{
  width: 420px;
  margin-top: 24px;
}

.special-offer-main-desc{
  margin-bottom: 20px;
}