.accept-offer-container {
  align-items: center;
  .btn-container {
    display: flex;
    flex-direction: row;
    button {
      font-family: 'Noto Sans Display';
      font-size: 16px;
      letter-spacing: 1px;
      margin: 1.5rem 1rem 0 0;
    }
  }
}

.button-container {
  display: flex;
}

.loading-build-content-button {
  cursor: progress;
}
