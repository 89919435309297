.reporting-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.reporting-main h1 {
  font-family: 'Noto Sans Display';
  color: #282828;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: -1;
  line-height: 27px;
}

.componenets-main {
  margin: 10px;
  height: 100%;
}

.ag-theme-alpine {
  --ag-borders: none;
}
.ag-theme-alpine .ag-row {
  --ag-range-selection-border-style: none;
  height: 48px;
}
.firstRow {
  background-color: #ffffff;
}

.secondRow {
  background-color: #f6f6f6;
}

.ReportingDetails {
  display: flex;
  flex-direction: column;
}

.row-reporting {
  width: 430px;
}
.reportDetails-heading {
  opacity: 1;
  color: rgba(40, 40, 40, 1);
  font-family: 'Noto Sans Display';
  font-size: 26px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 1px;
  text-align: left;
  line-height: 34px;
  margin-top: 15px;
  margin-bottom: 30px;
}

.reportingDetails-components {
  display: flex;
}

.size {
  font-size: 14px;
}

.dropDownWidth {
  width: -webkit-fill-available;
  padding-right: 7px;
  margin-top: 0.5rem;
}

.dropDownWidthLastOne {
  width: -webkit-fill-available;
  padding-right: 7px;
  margin-top: 15px;
}
.reportingTextarea {
  height: 197px;
  width: -webkit-fill-available;
  background-color: #fff;
  border-radius: 0;
  border: 1px solid #ccc;
  box-shadow: inset 0 0 5px 0 #ddd;
  font-size: 16px;
  padding: 12px;
}

.reportingTextarea:focus {
  border: 1px solid #ffb612;
  outline: 0;
}

.row-reporting p {
  opacity: 1;
  color: rgba(40, 40, 40, 1);
  font-family: 'Noto Sans Display';
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 16px;
  margin-bottom: 5px;
}

.ReportingDetails .footer-nav {
  display: flex;
}

.approval-reporting-default-message-container {
  margin: 30px 0 30px 0;
}

.approval-reporting-default-message {
  margin-top: 10px;
  width: 600px;
  font-weight: 400;
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.approval-reporting-input {
  width: 500px;
}

.approval-reporting-error-message {
  display: flex;
}
