.active {
  border-bottom: 1px solid black;
}
.reviews-btn-container {
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
}
.offer-information-data {
  min-height: 112px;
  height: 100%;
  width: 48%;
  border: 1px solid black;
  display: flex;
  justify-content: space-between;
  padding: 1.25rem;
  align-items: center;
  border-style: dashed;
  margin: 1%;
}
.offer-information-data-conatiner {
  padding: 1rem;
}

.more {
  margin-left: 17rem;
}

.hr {
  margin: 15px;
}

.root-view {
  width: 80%;
  margin-left: 10%;
}

.container-width {
  width: 98%;
  flex: none;
  height: auto;
}
