.method-of-communication {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
}
.distribution {
  display: flex;
  gap: 2rem;
}
.distribution-info {
  margin-bottom: 28px;
  width: 280px;
}
.distribution-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.choose-distribution {
  margin-bottom: 2rem;
  margin-top: 2rem;
  font-family: 'Noto Sans Display';
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.method-of-comm {
  margin-top: 2rem;
}
.distribution-add-button {
  padding: 0;
  margin-left: -4px;
}
.channels-details {
  margin-left: 27px;
}
.channels-details > label {
  margin-bottom: 0;
}
.dist-input-ext {
  width: 360px;
  height: 44px;
}
.distribution-channels .b-form-checkbox__control {
  margin-top: 2.5px;
}

.dist-map {
  margin-bottom: 5px;
}

.partner-with-trash{
  display: flex;
  align-items: baseline;
}