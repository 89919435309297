.next-back-btn-container {
  display: flex;
  gap: 0.8rem;
  margin: 5px auto;
}

.page-number {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 30px;
}

.mainRoot {
  display: flex;
  height: 100%;
}

.navbar {
  flex: 0 0 15%;
  min-height: 100%;
  overflow: auto;
  padding: var(--common-padding) 0;
  flex-direction: column;
  display: flex;
  box-shadow: 0px -3px 7px 0px #28282885;
  /* margin-left: 5px; */
}

.root-container {
  flex: 1 1 auto;
  padding: var(--common-padding);
  width: 85%;
  margin-bottom: 76px;
}

.root-container.dashboard {
  margin-bottom: 0;
  height: 100%;
}
.mainRoot.dashboard {
  height: 100%;
}

.nav-main {
  margin-left: 20px;
  margin-top: 20px;
}

.nav-main hr {
  margin: 0px 30px 15px 0px;
}
.heading {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #282828;
  font-weight: 900;
  font-size: 1rem;
}

.home {
  display: flex;
  flex-direction: column;
}

.resource {
  display: flex;
  flex-direction: column;
  flex-direction: 1 1 auto;
}
.home-items {
  margin: 0px 5px 0px 0px;
}

.resource-items {
  margin: 0;
  padding-right: 0.7rem;
}

.home-sub-items {
  display: flex;
  justify-content: space-between;
  margin: 5px 50px 0px 10px;
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.navbar-label {
  border-radius: 10px;
  padding: 0px 5px 0px 5px;
  color: white;
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  height: fit-content;
  font-weight: bold;
  width: 40px;
  text-align: center;
}

.all {
  background-color: #0d196d;
}

.submitted {
  background-color: #0273d0;
}

.approved {
  background-color: #0d7214;
}

.rejected {
  background-color: #b90001;
}

.draft {
  background-color: #727272;
}

.disableDiv {
  opacity: 0.5;
  pointer-events: none;
}

.editCloseButton {
  display: flex;
  justify-content: end;
  margin-right: 200px;
  cursor: pointer;
  font-family: 'Noto Sans Display';
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  padding: 1px;
  letter-spacing: 1px;
  line-height: 22px;
  text-underline-position: under;
}

.editCloseButton svg {
  width: 22px;
  height: 22px;
}

.app {
  background-color:#fbfbfb !important;
  height: 100vh;
  background-size: cover;
  overflow: auto;
}

.dasboard-table {
  background-color:#fbfbfb !important;
  height: 600px;
}